.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 2s cubic-bezier(0.17, 1, 0.68, 0.29);
  }
}

body {
  background-color: rgb(33, 33, 33);
}

.App-header {
  margin-top: -4rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(200, 200, 200, 0.4);
}

.App-link {
  color: #61dafb;
}

* {
  font-family: "Poppins", sans-serif;
}
.App-header p {
  padding-top: 12px;
  font-size: calc(8px + 2vmin);
}
